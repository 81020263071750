import { View, Text } from "react-native"
import React, { useState, useEffect } from "react"

export default function GameTimer({ start, multiplier }) {
    if (!start) {
        return null
    }
    const [time, setTime] = useState(start)
    useEffect(() => {
      setTime(start)
    }, [start])

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000)
        return () => {
            clearInterval(timerID)
        }
    }, [])

    const tick = () => {
        setTime(time => time + 1 * multiplier * 1e9)
    }
    return (
        <View>
            <Text>{fancyTimeFormat(time)}</Text>
        </View>
    )
}

function fancyTimeFormat(duration) {
    duration = duration / 1e9
    let negative = false
    if (duration < 0) {
        duration = duration * -1
        negative = true
    }
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600)
    var mins = ~~((duration % 3600) / 60)
    var secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = ""
    if (negative) {
        ret = "-"
    }
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "")
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "")
    ret += "" + secs
    return ret
}
