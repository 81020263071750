import React, { useEffect, useState } from "react"
import { Text } from "react-native"

export function AnimatedDots() {
    const [count, setCount] = useState(1)
    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count => (count % 3) + 1)
        }, 500)
        return () => clearInterval(interval)
    })
    return <Text>{[...new Array(count)].map(() => ".")}</Text>
}
