import React, { useContext, useEffect } from "react"
import { Button, SafeAreaView, View, Text, TextInput, Image } from "react-native"
import Navbar from "../../components/HomeScreen/Navbar"
import { ModalProvider } from "../../context/ModalContext"
import styles from "../../styles"
import leagueLogo from "../../assets/logo.jpg"

import { WebSocketContext } from "../../context/WebsocketContext"
import { AuthContext } from "../../context/AuthContext"
import { TouchableOpacity } from "react-native-gesture-handler"
import styled from "styled-components/native"

const GameCreationImage = styled.Image`
    width: 900 / 2;
    height: 360 / 2;
`
function GameCreationView() {
    const { ws } = useContext(WebSocketContext)
    const { auth } = useContext(AuthContext)
    const [lobbyID, setLobbyID] = React.useState("")

    const createLobby = () => {
        console.log("Creating Game Lobby")
        ws.send(JSON.stringify({ userID: auth.uid, msg: "createLobby" }))
    }

    const joinLobby = () => {
        console.log("Joining Game Lobby: ", lobbyID)
        ws.send(JSON.stringify({ msg: "joinLobby", lobbyID: lobbyID }))
    }

    return (
        <SafeAreaView style={styles.container}>
            <Navbar />
            <View style={styles.create_game_wrapper}>
                <View style={styles.container}>
                    <GameCreationImage source={leagueLogo} />
                </View>
                <View style={styles.container}>
                    <TouchableOpacity
                        style={{
                            flex: 1,
                            borderRadius: 5,
                            borderWidth: 2,
                            borderColor: "#3366cc",
                            backgroundColor: "#3366cc",
                        }}
                        onPress={createLobby}>
                        <Text style={{ fontSize: 22, margin: 5, color: "rgba(255,255,255,1)" }}>
                            Create Private Room
                        </Text>
                    </TouchableOpacity>
                </View>
                <View
                    style={{
                        flex: 1,
                        alignItems: "center",
                        border: 2,
                        borderRadius: 5,
                        borderColor: "#3366cc",
                    }}>
                    <TextInput
                        style={{
                            fontSize: 22,
                            margin: 5,
                            borderWidth: 2,
                            borderRadius: 5,
                            borderColor: "grey",
                            textAlign: "center",
                        }}
                        onChangeText={lobbyid => setLobbyID(lobbyid)}
                        placeholder="LobbyID"
                    />
                    <TouchableOpacity
                        style={{
                            borderRadius: 5,
                            borderWidth: 2,
                            borderColor: "#3366cc",
                            backgroundColor: "#3366cc",
                        }}
                        onPress={joinLobby}>
                        <Text style={{ fontSize: 22, margin: 5, color: "rgba(255,255,255,1)" }}>Join Private Room</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </SafeAreaView>
    )
}

export default GameCreationView
