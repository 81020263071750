import React, { useState, useEffect, useContext } from "react"
import { Text, View } from "react-native"
import { WebSocketContext } from "../context/WebsocketContext"

import { WaitingView } from "../components/LobbyScreen/WaitingView"
import { Game } from "../components/GameScreen/Game"
import { GameFinishView } from "../components/LobbyScreen/GameFinishView"
import GameCreationView from "../components/LobbyScreen/GameCreationView"
import { useNavigation } from "@react-navigation/native"
import { ModalProvider } from "../context/ModalContext"
import { AuthContext } from "../context/AuthContext"
import useModal from "../hooks/useSetModal"
import UsernameModal from "../components/global/UsernameModal"
export const LobbyContext = React.createContext()
import styled from "styled-components/native"
import { AnimatedDots } from "../components/global/AnimatedDots"

const ConnectView = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: row;
`

function LobbyScreen({ route }) {
    let lobbyId
    if (route && route.params) {
        lobbyId = route.params.lobbyId
    }

    const { ws } = useContext(WebSocketContext)
    const { auth } = useContext(AuthContext)
    const setModal = useModal()
    const navigation = useNavigation()
    // This will flash for a short moment even when user is logged in. Maybe auth has a state like "Logging in"?
    // This can be cleaner with auth.onChanged or so?
    if (!auth.currentUser && auth._isInitialized) {
        return <UsernameModal></UsernameModal>
    }
    if (!ws) {
        // This could be the Screen but with loading symbols at users or so?
        // Still looks shit :D
        //Maybe an empty "WaitingView" with a loading symbol?
        return (
            <ConnectView>
                <Text>Connecting</Text> <AnimatedDots />
            </ConnectView>
        )
    }
    const [endScreen, setEndscreen] = useState(false)
    const [lobby, setLobby] = useState()

    useEffect(() => {
        ws.onmessage = function (res) {
            const data = JSON.parse(res.data)
            console.log("Message Received")
            console.log(data)
            if (data.msg === "error") {
                console.log("WS ERROR: ", data)
                if (data.details.error === "lobby not found") {
                    console.log("Lobby with id " + lobbyId + " not found")
                    // TODO: Let the user know this....
                }
            } else if (data.msg === "lobbyUpdated") {
                if (lobby && lobby.lobbyId != lobbyId) {
                    navigation.setParams({ lobbyId: lobby.lobbyId })
                }
                //console.log("Update Lobby")
                // Check if someone finished:
                if (lobby) {
                    if (data.details.state === "game" && lobby.state === "game") {
                        //console.log("IngameUpdate")
                        //console.log(lobby)
                        //console.log(data.details)
                        const newfinished = data.details.users.filter(user => {
                            if (
                                user.finished &&
                                !lobby.users
                                    .filter(user => user.finished)
                                    .map(user => user.uid)
                                    .includes(user.uid)
                            ) {
                                return true
                            } else return false
                        })
                        //console.log("New finished players: " + newfinished)
                    }
                }
                setLobby(data.details)
            } else if (data.msg === "gameStarted") {
                //console.log("Start game")
                setLobby(data.details)
            } else if (data.msg === "gameEnded") {
                //console.log("Game Ended")
                setEndscreen(true)
                setLobby(data.details)
            } else if (data.msg === "noLobby") {
                //console.log("User not connected in lobby. Game Creation View")
                if ("" != lobbyId) {
                    navigation.setParams({ lobbyId: "" })
                }
                setLobby(null)
            } else {
                console.log("Unknown Message: " + data.msg)
            }
        }
        return () => {
            ws.onmessage = null
        }
    })
    if (!lobby) {
        //Loading
        const checkLobbyStatus = () => {
            if (ws.readyState === WebSocket.OPEN) {
                //console.log("Asking for status")
                if (lobbyId) {
                    ws.send(JSON.stringify({ msg: "joinLobby", lobbyID: lobbyId }))
                } else {
                    ws.send(JSON.stringify({ msg: "getLobby" }))
                }
            } else {
                // Queue a retry
                console.log("WS not ready yet... ")
                setTimeout(() => {
                    checkLobbyStatus()
                }, 1000)
            }
        }
        checkLobbyStatus()
        return <GameCreationView />
    }
    let currentView = null
    if (lobby.state === "wait" && endScreen === true) {
        // Watching Game Results
        currentView = <GameFinishView setEndscreen={setEndscreen} />
    } else if (lobby.currentUser.finished || lobby.currentUser.surrendered) {
        // This Player finished and checkouts out Game Results
        console.log("Player Finished")
        if (endScreen === false) {
            setEndscreen(true)
        }
        currentView = <GameFinishView setEndscreen={setEndscreen} />
    } else if (lobby.state === "wait") {
        // Waiting in Lobby
        currentView = (
            <ModalProvider>
                <WaitingView setEndscreen={setEndscreen} />
            </ModalProvider>
        )
    } else if (lobby.state === "game") {
        // Lobby is ingame and player not finished
        currentView = <Game />
    } else {
        currentView = <Text>Error, Undefined Lobby State: </Text>
    }

    return <LobbyContext.Provider value={{ lobby: lobby, setLobby: setLobby }}>{currentView}</LobbyContext.Provider>
}

export default LobbyScreen
