import { View, Text, Touchable } from "react-native"
import React, { useContext } from "react"
import { signInWithPopup, getAuth, linkWithCredential, linkWithPopup } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth"
import { AuthContext } from "../../context/AuthContext"
import { TouchableOpacity } from "react-native-gesture-handler"
import styled from "styled-components/native"
import colors from "../../styles/colors"

const GoogleButton = styled(TouchableOpacity)`
    flex-direction: row;
    background-color: ${colors.BLUE};
    border-radius: 5px;
    padding: 5px;
    color: ${colors.WHITE};
`
const googleProvider = new GoogleAuthProvider()

const Google = () => {
    const { auth } = useContext(AuthContext)
    const googlePopUpSignIn = () => {
        console.log("Logging in per Popup")
        console.log(auth)
        linkWithPopup(auth.currentUser, googleProvider)
            .then(res => {
                if (res.credential) {
                    // do stuff;
                    console.log("Connected with Google")
                }
            })
            .catch(error => {
                console.log(error)
            })
        //signInWithPopup(auth, provider)
        //        .then(result => {
        //                // This gives you a Google Access Token. You can use it to access the Google API.
        //                const credential = GoogleAuthProvider.credentialFromResult(result)
        //                linkCredential(credential)
        //            })
        //            .catch(error => {
        //                // Handle Errors here.
        //                const errorCode = error.code
        //                const errorMessage = error.message
        //                // The email of the user's account used.
        //                const email = error.customData.email
        //                // The AuthCredential type that was used.
        //                const credential = GoogleAuthProvider.credentialFromError(error)
        //                console.log(error)
        //            })
    }
    const linkCredential = credential => {
        console.log("Linking credential")
        linkWithCredential(auth.currentUser, credential)
            .then(usercred => {
                const user = usercred.user
                console.log("Anonymous account successfully upgraded", user)
            })
            .catch(error => {
                console.log("Error upgrading anonymous account", error)
            })
    }

    return (
        <View>
            <GoogleButton onPress={googlePopUpSignIn}>
                <Text>Google</Text>
            </GoogleButton>
        </View>
    )
}

export default Google
