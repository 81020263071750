import React, { useContext, useState } from "react"
import { Image, SafeAreaView, View, Text, TouchableOpacity } from "react-native"
import { LobbyContext } from "../../screens/LobbyScreen"
import { WebSocketContext } from "../../context/WebsocketContext"
import { ModalProvider } from "../../context/ModalContext"
import styles from "../../styles"
import { AnimatedDots } from "../../components/global/AnimatedDots"
import { PlayerView } from "../../components/LobbyScreen/PlayerView"

import Navbar from "../HomeScreen/Navbar"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faSlidersH } from "@fortawesome/free-solid-svg-icons"
import useModal from "../../hooks/useSetModal"
import SettingsView from "./SettingsView"
import leagueLogo from "../../assets/logo.jpg"
import useWindowWidth from "../../hooks/useWindowWidth"
import LeaveLobbyButton from "./LeaveLobbyButton"

export function WaitingView({ setEndscreen }) {
    const { lobby } = useContext(LobbyContext)

    const { ws } = useContext(WebSocketContext)
    const windowWidth = useWindowWidth()

    const [showID, setshowID] = useState(false)
    const start = () => {
        ws.send(JSON.stringify({ msg: "startGame" }))
    }
    // Show Settings per Default if screen is > 1000
    const [showSettings, setshowSettings] = useState(windowWidth > 1000)
    const prevGame = () => {
        setEndscreen(true)
    }

    return (
        <ModalProvider>
            <SafeAreaView style={styles.container}>
                <Navbar />
                <View style={{ alignContent: "center", alignSelf: "center" }}>
                    <Image style={{ width: 900 / 3, height: 360 / 3, backgroundColor: "green" }} source={leagueLogo} />
                </View>
                <View
                    style={{
                        position: "absolute",
                        top: 75,
                        left: 15,
                        zIndex: 500,
                        backgroundColor: "rgba(255,255,255,0.9)",
                        borderWidth: 2,
                        borderRadius: 5,
                        padding: 5,
                    }}>
                    <TouchableOpacity style={{ flex: 1 }} onPress={() => setshowSettings(!showSettings)}>
                        <FontAwesomeIcon icon={faSlidersH} />
                    </TouchableOpacity>
                    {showSettings && (
                        <View
                            style={{
                                flex: 1,
                                marginTop: 15,
                                width: 300,
                                padding: 5,
                            }}>
                            <SettingsView />
                        </View>
                    )}
                </View>
                <View style={styles.wait_wrapper}>
                    <Text style={styles.wait_header}>
                        Waiting for other players <AnimatedDots />
                    </Text>

                    <TouchableOpacity onPress={() => setshowID(!showID)} style={{ flexDirection: "row" }}>
                        <Text style={{ fontWeight: "bold" }}>LobbyID: </Text>
                        <Text style={{ backgroundColor: showID === false ? "black" : "white" }}>{lobby.lobbyId}</Text>
                    </TouchableOpacity>

                    <PlayerView lobby={lobby} />
                    <TouchableOpacity onPress={start}>
                        <View style={styles.wait_start_button}>
                            <Text style={styles.wait_start_button_text}>Start Game</Text>
                        </View>
                    </TouchableOpacity>
                    <View style={styles.wait_buttons_wrapper}>
                        <TouchableOpacity onPress={prevGame}>
                            <View style={styles.wait_start_button}>
                                <Text style={styles.wait_start_button_text}>Lobby History</Text>
                            </View>
                        </TouchableOpacity>
                        <LeaveLobbyButton />
                    </View>
                </View>
            </SafeAreaView>
        </ModalProvider>
    )
}
