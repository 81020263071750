import { View, Text, TouchableOpacity } from "react-native"
import React, { useContext, useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { WebSocketContext } from "../../context/WebsocketContext"
import HomeScreenButton from "./HomeScreenButton"
import useModal from "../../hooks/useSetModal"
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons"
import UsernameModal from "../global/UsernameModal"
import { AuthContext } from "../../context/AuthContext"

export default function JoinFriendsButton({ style, coords }) {
    const navigation = useNavigation()
    const { ws, connected } = useContext(WebSocketContext)
    // Disable Button if no Websocket is connected
    const { token } = useContext(AuthContext)
    const setModal = useModal()
    const joinFrinds = () => setModal(<UsernameModal />)
    let opacity = 1
    if (!connected) {
        opacity = 0.4
    }

    const onClick = () => {
        if (token) {
            navigation.navigate("LobbyScreen")
        }
        if (!token) {
            setModal(
                <UsernameModal
                    onSuccess={() => {
                        setModal(null)
                        navigation.navigate("LobbyScreen")
                    }}
                />,
            )
        }
    }

    return <HomeScreenButton icon={faArrowRightToBracket} title="Join Friends" onPress={onClick} coords={coords} />
}
