import React from "react"
import { View, Image } from "react-native"
import useWindowWidth from "../../hooks/useWindowWidth"
import styles from "../../styles"
import logo from "../../assets/wikipedia_240x240.png"
import MenuToggle from "../HomeScreen/MenuToggle"
import NavbarLinks from "../HomeScreen/NavbarLinks"
import GameInfo from "./GameInfo"
import styled from "styled-components/native"
import colors from "../../styles/colors"

const Navbar = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #ffffff;
    height: 60px;
    z-index: 999;
    padding: 0 16px;
    box-shadow: 4px 4px 16px ${colors.DARKGREY};
    background-image: ${props => `linear-gradient(to right,#b0c4de,#b0c4de24 , #b0c4de)`};
`

function GameNavbar() {
    const windowWidth = useWindowWidth()
    return (
        <Navbar>
            <Image style={styles.navbar_logo} source={logo} />
            {windowWidth > 1000 ? (
                <>
                    <GameInfo wide />
                    <MenuToggle>
                        <NavbarLinks compact={true} />
                    </MenuToggle>
                </>
            ) : (
                <>
                    <GameInfo />
                    <MenuToggle>
                        <NavbarLinks compact={true} />
                    </MenuToggle>
                </>
            )}
        </Navbar>
    )
}

export default GameNavbar
