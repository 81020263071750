import { useState, useLayoutEffect } from "react"
import { Dimensions } from "react-native"

// useWindowWidth returns the width of the window dimension.
// the width gets automatically updated on window dimension changes.
function useWindowWidth() {
    const [width, setWidth] = useState(Dimensions.get("window").width)
    useLayoutEffect(() => {
        const onChange = ({ window }) => setWidth(window.width)
        Dimensions.addEventListener("change", onChange)
        return () => Dimensions.removeEventListener("change", onChange)
    }, [])
    return width
}

export default useWindowWidth
