import React from "react"
import { Platform, Text, View } from "react-native"
import { StatusBar } from "expo-status-bar"
import HomeScreen from "./src/screens/HomeScreen"
import AccountScreen from "./src/screens/AccountScreen"
import WaitScreen from "./src/screens/WaitScreen"
import LobbyScreen from "./src/screens/LobbyScreen"
import { ModalProvider } from "./src/context/ModalContext"

import { createStackNavigator } from "@react-navigation/stack"
import { NavigationContainer } from "@react-navigation/native"

import { AuthProvider } from "./src/context/AuthContext"
import { WebsocketProvider } from "./src/context/WebsocketContext"

import * as Linking from "expo-linking"

const Stack = createStackNavigator()

import "react-native-gesture-handler"
const config = {
    screens: {
        HomeScreen: "home",
        AccountScreen: "account",
        WaitScreen: "wait",
        LobbyScreen: "lobby/:lobbyId?",
    },
}

const linking = {
    prefixes: [Linking.createURL("/")],
    config,
}

function App() {
    return (
        <NavigationContainer linking={linking}>
            <AuthProvider>
                <WebsocketProvider>
                    <ModalProvider>
                        <Stack.Navigator
                            initialRouteName="HomeScreen"
                            headerMode="none"
                            screenOptions={{ animationEnabled: false }}>
                            <Stack.Screen name="HomeScreen" component={HomeScreen} />
                            <Stack.Screen name="AccountScreen" component={AccountScreen} />
                            <Stack.Screen name="WaitScreen" component={WaitScreen} />
                            <Stack.Screen name="LobbyScreen" component={LobbyScreen} />
                        </Stack.Navigator>
                    </ModalProvider>
                </WebsocketProvider>
            </AuthProvider>
        </NavigationContainer>
    )
}

export default App
