import React, { useState, useContext, useEffect } from "react"
import { View, Text, TextInput, TouchableOpacity } from "react-native"

import styles from "../../styles"

import * as WebBrowser from "expo-web-browser"
import { GoogleAuthProvider, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup } from "firebase/auth"
import { Button } from "react-native"

import { AuthContext } from "../../context/AuthContext"

import styled from "styled-components/native"
import GoogleLogin from "./GoogleLogin"
import colors from "../../styles/colors"
import icon from "../../assets/logoIcon.png"

const WikileagueButton = styled.TouchableOpacity`
    background-color: ${colors.BLUE};
    border-radius: 50%;
    padding: 5px;
    background-image: url(${icon});
    background-size: cover;
    background-position: center;
    width: 36px;
    height: 36px;
    margin: 12px;
`

// Not sure what this was for... x(
// Seems Like it was for the google login button
WebBrowser.maybeCompleteAuthSession()

function WikiLeagueLogin({ onSuccess }) {
    const [show, setShow] = useState(false)
    let { auth } = useContext(AuthContext)
    // Listen for authentication state to change.
    useEffect(() => {
        const oASC = onAuthStateChanged(auth, user => {
            if (user != null) {
                console.log(`${user.email} is logged in!`)
                if (onSuccess) {
                    onSuccess()
                }
            } else {
            }
        })
        return oASC.remove
    })

    // Prepare the Email Login Button
    const onSubmit = () => {
        signInWithEmailAndPassword(auth, username, password).catch(error => {
            console.log(error)
        })
    }
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    return (
        <>
            <WikileagueButton onPress={() => setShow(val => !val)}></WikileagueButton>
            {show && (
                <View style={styles.login_form}>
                    <Text style={styles.login_form_header}>Login</Text>
                    <Text style={styles.login_form_label}>Username</Text>
                    <TextInput
                        onChangeText={user => setUsername(user)}
                        editable
                        maxLength={40}
                        style={styles.login_form_input}
                    />
                    <Text style={styles.login_form_label}>Password</Text>
                    <TextInput
                        onChangeText={pass => setPassword(pass)}
                        editable
                        maxLength={40}
                        style={styles.login_form_input}
                        secureTextEntry
                    />
                    <TouchableOpacity onPress={onSubmit} style={styles.login_form_submit}>
                        <Text style={styles.login_form_submit_text}>Login</Text>
                    </TouchableOpacity>
                </View>
            )}
        </>
    )
}

export default WikiLeagueLogin
