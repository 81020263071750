import { View, Text } from "react-native"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { TouchableOpacity } from "react-native"
import { faFlag } from "@fortawesome/free-solid-svg-icons"
import { WebSocketContext } from "../../context/WebsocketContext"
import { useContext } from "react"
import styled from "styled-components/native"
const StyledSurrenderButton = styled.View`
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    padding: 10px;
    border-width: 1px;
    border-radius: 5px;
    border-color: rgba(255, 255, 255, 0);
`

export default function SurrenderButton() {
    const { ws } = useContext(WebSocketContext)

    const surrender = () => {
        ws.send(JSON.stringify({ msg: "surrender" }))
    }
    return (
        <StyledSurrenderButton>
            <TouchableOpacity onPress={surrender}>
                <FontAwesomeIcon icon={faFlag} />
            </TouchableOpacity>
        </StyledSurrenderButton>
    )
}
