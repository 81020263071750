import React, { useState } from "react"
import { TouchableOpacity } from "react-native"
import styles from "../../styles"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

function MenuToggle({ children }) {
    const [show, setShow] = useState(false)
    const toggle = () => setShow(show => !show)
    return (
        <>
            <TouchableOpacity style={styles.menu_button} onPress={toggle}>
                <FontAwesomeIcon icon={faBars} />
            </TouchableOpacity>
            {show && children}
        </>
    )
}

export default MenuToggle
