import { View, Text } from "react-native"
import React from "react"
import pic from "../../assets/DefaultPic.png"
import styled from "styled-components/native"
const StyledImage = styled.Image`
    width: ${props => props.size};
    height: ${props => props.size};
    border: 2px solid #000;
    border-radius: ${props => props.size};
`

const ProfilePic = ({ size }) => {
    if (!size) {
        size = "32px"
    }
    return (
        <View>
            <StyledImage size={size} source={pic} />
        </View>
    )
}

export default ProfilePic
