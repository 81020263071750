import React, { useContext, useState, useEffect } from "react"
import { SafeAreaView, View, Text, TextInput } from "react-native"
import { Button } from "react-native"
import Navbar from "../components/HomeScreen/Navbar"
import styles from "../styles"
import { AuthContext } from "../context/AuthContext"
import axios from "axios"
import { acc } from "react-native-reanimated"
import { useNavigation } from "@react-navigation/native"

import SERVER_CONFIG from "../config/server"
import Connect from "../components/AccountScreen/Connect"

function AccountScreen() {
    const { auth, token } = useContext(AuthContext)

    const [changing, setChanging] = useState(false)

    const [displayName, setDisplayName] = useState(null)
    const [originalName, setOriginalName] = useState(null)

    const navigation = useNavigation()

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            if (!displayName && auth && auth.currentUser) {
                setDisplayName(auth.currentUser.displayName)
                setOriginalName(auth.currentUser.displayName)
            }
        })
    }, [auth])

    let accountData
    if (!auth || !auth.currentUser) {
        accountData = <Text>Not logged in</Text>
    } else {
        accountData = (
            <>
                <Text>Account Screen</Text>
                <Text>Email: {auth.currentUser.email}</Text>
                <Text>Username: </Text>
                <TextInput
                    disabled={changing}
                    onChangeText={setDisplayName}
                    onBlur={input => {
                        const newName = input.target.value
                        setChanging(true)
                        const url = `${
                            SERVER_CONFIG.workers[Math.floor(Math.random() * SERVER_CONFIG.workers.length)]
                        }/user/modify`
                        axios
                            .post(url, { name: newName }, { headers: { Authorization: "Bearer " + token } })
                            .then(response => {
                                setOriginalName(displayName)
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                                setDisplayName(originalName)
                            })
                            .then(() => {
                                setChanging(false)
                            })
                    }}
                    placeholder="Display name"
                    value={displayName || ""}
                />
                <Text>{auth.currentUser.toString()}</Text>

                <Button
                    title="Logout"
                    onPress={() => {
                        auth.signOut()
                        navigation.navigate("HomeScreen")
                        // Return to login screen
                        // Replace this with login modal once we've implemented it
                    }}
                />
            </>
        )
    }

    // Most of this like the user Info will most likely be another Component later
    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.account_wrapper}>
                <Navbar />

                {accountData}
                <Connect />
            </View>
        </SafeAreaView>
    )
}

export default AccountScreen
