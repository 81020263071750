import { StyleSheet } from "react-native"
const styles = StyleSheet.create({
    // ----------------------------------
    //  Lobby Styles
    // ----------------------------------
    container: {
        flex: 1,
    },
    GameFinishScreen_wrapper: {
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        flex: 1,
    },
    GameFinishScreen_header: {
        fontSize: 30,
    },
    GameFinishScreen_playerscore: {
        flex: 3,
    },
    GameFinishScreen_buttons_wrapper: {
        flex: 1,
        alignSelf: "stretch",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    GameFinishScreen_buttons_LRwrapper: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
    },
    GameFinishScreen_buttons_LeftRight: {
        flex: 1,
    },
})
export default styles
