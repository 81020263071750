import { useNavigation } from "@react-navigation/native"
import React from "react"
import { SafeAreaView, View, Text, TouchableOpacity } from "react-native"
import { AnimatedDots } from "../components/global/AnimatedDots"
import { ModalProvider } from "../context/ModalContext"
import styles from "../styles"

export default function WaitScreen() {
    const navigation = useNavigation()
    const start = () => {
        navigation.navigate("GameScreen")
    }
    const abort = () => {
        navigation.navigate("HomeScreen")
    }
    return (
        <SafeAreaView style={styles.container}>
            <ModalProvider>
                <View style={styles.wait_wrapper}>
                    <Text style={styles.wait_header}>
                        Waiting for other players <AnimatedDots />
                    </Text>
                    <View style={styles.wait_buttons_wrapper}>
                        <TouchableOpacity onPress={start}>
                            <View style={styles.wait_start_button}>
                                <Text style={styles.wait_start_button_text}>Start Game</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={abort}>
                            <View style={styles.wait_abort_button}>
                                <Text style={styles.wait_abort_button_text}>Abort</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </ModalProvider>
        </SafeAreaView>
    )
}
