import { View, Text, TouchableOpacity } from "react-native"
import React, { useContext, useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { WebSocketContext } from "../../context/WebsocketContext"
import HomeScreenButton from "./HomeScreenButton"
import { faPeopleGroup, faPlay } from "@fortawesome/free-solid-svg-icons"

export default function CreatePrivateLobbyButton({ style, coords }) {
    const navigation = useNavigation()
    const { ws, connected } = useContext(WebSocketContext)
    // Disable Button if no Websocket is connected

    let opacity = 1
    if (!connected) {
        opacity = 0.4
    }

    const onClick = () => {
        navigation.navigate("LobbyScreen")
        ws.send(JSON.stringify({ msg: "createLobby" }))
    }

    // I dont know why style is a array here....
    //console.log(style)
    /*return (
        <TouchableOpacity onPress={() => onClick()} disabled={!connected} style={[style, { opacity: opacity }]}>
            <Text
                style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                    textShadowColor: "black",
                    textShadowRadius: 5,
                }}>
                Create Private Lobby
            </Text>
        </TouchableOpacity>
    )*/
    return <HomeScreenButton title="Create New Game" onPress={onClick} icon={faPeopleGroup} coords={coords} />
}
