import React, { useContext } from "react"
import { TouchableOpacity, View } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import styles from "../../styles"
import { useNavigation } from "@react-navigation/native"
import { AuthContext } from "../../context/AuthContext"
import styled from "styled-components/native"
import colors from "../../styles/colors"
import ProfilePic from "../global/ProfilePic"

const AccountButtonContainer = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Text = styled.Text`
    color: ${colors.BLACK};
    font-size: 16px;
    margin: 10px;
`

function AccountButton() {
    const { auth } = useContext(AuthContext)
    const navigation = useNavigation()
    return (
        <TouchableOpacity style={styles.account_button} onPress={() => navigation.navigate("AccountScreen")}>
            <AccountButtonContainer>
                <Text>{auth.currentUser?.displayName ? auth.currentUser?.displayName : "Guest"}</Text>
                <ProfilePic size="32px" />
            </AccountButtonContainer>
        </TouchableOpacity>
    )
}

export default AccountButton
