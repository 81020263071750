//import { View, Text } from "react-native"
//import React from "react"
import styled from "styled-components/native"
//import colors from "../../styles/colors"
//import { useWindowWidth } from "../../hooks/useWindowWidth"

export const Container = styled.View`
    flex: 1;
`
export const BoldText = styled.Text`
    font-weight: bold;
`
