import { faFlagCheckered, faUserFriends } from "@fortawesome/free-solid-svg-icons"
import { Text, View } from "react-native"
import { useState, useContext } from "react"
import { TouchableOpacity } from "react-native"
import styles from "../../styles"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { LobbyContext } from "../../screens/LobbyScreen"

import styled from "styled-components/native"

const StyledShowView = styled.View`
    background-color: rgba(255, 255, 255, 0.9);
    border-width: 1px;
    border-radius: 5px;
`

const StyledUserView = styled.View`
    flex-direction: row;
`
const MarginText = styled.Text`
    margin-left: 2px;
    margin-right: 2px;
`
function IngamePlayerList() {
    const [show, setShow] = useState(false)
    const toggle = () => setShow(show => !show)
    const { lobby } = useContext(LobbyContext)
    if (show) {
        const finishedIcon = <FontAwesomeIcon icon={faFlagCheckered} />
        return (
            <StyledShowView>
                <TouchableOpacity style={styles.menu_button} onPress={toggle}>
                    <FontAwesomeIcon icon={faUserFriends} />
                </TouchableOpacity>
                {lobby.users.map(user => {
                    return (
                        <StyledUserView key={user.uid}>
                            {user.finished && finishedIcon}
                            <MarginText key={user.uid}>
                                {user.displayname}: {user.clicks}
                            </MarginText>
                        </StyledUserView>
                    )
                })}
            </StyledShowView>
        )
    }
    return (
        <StyledShowView>
            <TouchableOpacity style={styles.menu_button} onPress={toggle}>
                <FontAwesomeIcon icon={faUserFriends} />
            </TouchableOpacity>
        </StyledShowView>
    )
}

export default IngamePlayerList
