import { View, Text } from "react-native"
import React from "react"
import HomeScreenButton from "./HomeScreenButton"
import { faBars, faChartSimple, faCog } from "@fortawesome/free-solid-svg-icons"

const SettingsButton = ({ coords }) => {
    return <HomeScreenButton coords={coords} title="Settings" icon={faCog} />
}

export default SettingsButton
