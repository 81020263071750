import { View, Text, Touchable } from "react-native"
import React from "react"
import { getAuth, linkWithCredential } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth"
import { AuthContext } from "../../context/AuthContext"
import { TouchableOpacity } from "react-native-gesture-handler"
import styled from "styled-components/native"
import Google from "./GoogleLink"

const Connect = () => {
    return (
        <View>
            <Text>Connect</Text>
            <Google />
        </View>
    )
}

export default Connect
