import colors from "./colors"
import { StyleSheet } from "react-native"

const styles = StyleSheet.create({
    // ----------------------------------
    // Global Styles
    // ----------------------------------
    container: {
        flex: 1,
    },
    modal_wrapper: {
        position: "absolute",
        zIndex: 1000,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    modal: {
        maxWidth: 500,
        minWidth: 300,
        padding: 10,
        backgroundColor: colors.WHITE,
        flexDirection: "column",
        borderRadius: 1,
        borderColor: colors.BLACK,
        borderRadius: 5,
        shadowColor: colors.BLACK,
        shadowOffset: { width: 5, height: 5 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        //flexGrow:1,
    },
    modal_title: {
        height: 40,
        fontSize: 30,
        color: colors.BLACK,
    },
    modal_body: {},
    // ----------------------------------
    // LoginScreen Styles
    // ----------------------------------
    login_wrapper: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.WHITE,
    },
    login_form: {
        maxWidth: 500,
        flex: 1,
    },
    login_form_header: {
        textAlign: "center",
        fontSize: 30,
        color: colors.BLACK,
        fontWeight: "bold",
    },
    login_form_label: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
    },
    login_form_input: {
        borderBottomColor: "#000000",
        borderBottomWidth: 1,
        marginLeft: 20,
        marginRight: 20,
    },
    login_form_submit: {
        backgroundColor: colors.BLUE,
        padding: 10,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,
        borderRadius: 5,
    },
    login_form_submit_text: {
        color: "#FFFFFF",
        textAlign: "center",
    },
    // ----------------------------------
    // HomeScreen Styles
    // ----------------------------------
    home_wrapper: {
        flex: 1,
        alignContent: "center",
        justifyContent: "center",
    },
    navbar: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.5)",
        height: 60,
        zIndex: 999,
    },
    navbar_logo: {
        height: 25,
        width: 25,
        margin: 10,
    },
    navbar_links: {
        flexDirection: "row",
        justifyContent: "space-evenly",
    },
    navbar_links_compact: {
        position: "absolute",
        top: 60,
        left: 0,
        right: 0,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255,0.8)",
        zIndex: 999,
    },
    navbar_link: {
        padding: 10,
    },
    navbar_link_text: {
        fontSize: 20,
    },
    account_button: {
        padding: 10,
    },
    menu_button: {
        padding: 10,
    },
    // This one doesnt work for now. i need to dynamically change the abolute position of this in CircleView thingy
    home_circle_button: {
        backgroundColor: "#3366cc",
        borderWidth: 10,
        borderColor: "#3366cc",
        borderRadius: "50%",
        height: 80,
        width: 80,
        alignContent: "center",
        justifyContent: "center",
        outlineColor: "FFFFFF",
        outlineStyle: "solid",
        outlineWidth: 1,
    },
    // ----------------------------------
    // AccountScreen Styles
    // ----------------------------------
    home_wrapper: {
        flex: 1,
    },
    // ----------------------------------
    // CreateGameForm Styles
    // ----------------------------------
    create_game_form: {},
    create_game_form_label: {
        marginTop: 10,
    },
    create_game_form_submit: {
        backgroundColor: colors.BLUE,
        padding: 10,
        marginTop: 20,
        borderRadius: 5,
        color: colors.WHITE,
    },
    create_game_form_submit_text: {
        color: colors.WHITE,
    },
    create_game_timelimit: {
        color: colors.GREEN,
        fontSize: 12,
        textAlign: "center",
    },
    create_game_slider: {},
    // ----------------------------------
    // GameCreation Screen Styles
    // ----------------------------------
    create_game_wrapper: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        //alignSelf: "stretch",
    },
    // ----------------------------------
    // WaitScreen Styles
    // ----------------------------------
    wait_wrapper: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        alignSelf: "center",
        maxWidth: 480,
    },
    wait_header: {
        flex: 1,
        fontSize: 30,
        width: 285,
    },
    wait_buttons_wrapper: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "center",
    },
    wait_start_button: {
        backgroundColor: colors.BLUE,
        padding: 10,
        borderRadius: 5,
        color: colors.WHITE,
        alignSelf: "auto",
        margin: 5,
    },
    wait_start_button_text: {
        color: colors.WHITE,
    },
    wait_abort_button: {
        backgroundColor: colors.RED,
        padding: 10,
        borderRadius: 5,
        alignSelf: "auto",
        margin: 5,
    },
    wait_abort_button_text: {
        color: colors.WHITE,
    },
    // ----------------------------------
    // GameScreen Styles
    // ----------------------------------
    game_wrapper: {
        flex: 1,
    },
    game: {
        flex: 1,
    },
})

export default styles
