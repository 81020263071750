import { ScrollView, Text, View } from "react-native"
import { BoldText } from "../styledcomps/StyledComps"
import styled from "styled-components/native"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faCrown, faUser } from "@fortawesome/free-solid-svg-icons"
import ProfilePic from "../global/ProfilePic"
import colors from "../../styles/colors"

const StyledPlayerView = styled(View)`
    align-items: "center";
    align-self: "stretch";
    flex: 1;
    margin: 5px;
    padding: 5px;
`
const StyledPlayer = styled(View)`
    flex: auto;
    align-self: stretch;
    flex-direction: row;
    align-content: center;
    justify-content: left;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    border-color: #000;
    border-width: 1px;
    background-image: ${props => `linear-gradient(to right,#b0c4de,#b0c4de24 , #b0c4de)`};
    box-shadow: 1px 1px 1px #333;
`
const StyledScrollView = styled(ScrollView)`
    align-self: stretch;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
`
const StyledCrown = styled(FontAwesomeIcon)`
    color: ${props => props.color};
    text-shadow: 0 0 3px #000;
`
const StyledUser = styled.View`
    margin: 5px;
`
export function PlayerView({ lobby }) {
    return (
        <StyledPlayerView>
            <ScrollView>
                {lobby.users
                    .sort((ua, ub) => {
                        let medalsA = calculateMedals(ua.userId, lobby)
                        let medalsB = calculateMedals(ub.userId, lobby)
                        return (
                            medalsA.gold * 3 +
                            medalsA.silver * 2 +
                            medalsA.bronze -
                            (medalsB.gold * 3 + medalsB.silver * 2 + medalsB.bronze)
                        )
                    })
                    .map(user => {
                        let crown = <StyledCrown icon={faUser} />
                        if (user.uid === lobby.owner) {
                            crown = <StyledCrown color={"gold"} icon={faCrown} />
                        }
                        return (
                            // Medals Probably break when not balanced... Need to flex this correctly
                            <StyledPlayer key={user.uid}>
                                <StyledUser>
                                    <ProfilePic size={"32px"} />
                                </StyledUser>
                                <View>
                                    <BoldText>{user.displayname}</BoldText>
                                    <Text> {printMedals(calculateMedals(user.uid, lobby))}</Text>
                                </View>
                            </StyledPlayer>
                        )
                    })}
            </ScrollView>
        </StyledPlayerView>
    )
}
function calculateMedals(userId, lobby) {
    let gold = 0
    let silver = 0
    let bronze = 0
    let last = 0
    if (lobby.history) {
        lobby.history.map(game => {
            if (userId === game.users[0].uid && game.users[0].finished) {
                gold++
                return
            }
            if (game.users.length >= 2) {
                if (userId === game.users[1].uid && game.users[1].finished) {
                    silver++
                    return
                }
            }
            if (game.users.length >= 3) {
                if (userId === game.users[2].uid && game.users[2].finished) {
                    bronze++
                    return
                }
            }
            if (game.users.length >= 4) {
                if (
                    // If user was last or has not finished, give him a last place
                    userId === game.users[game.users.length - 1].uid ||
                    game.users.map(usr => {
                        if (usr.uid === userId && usr.finished) {
                            return true
                        }
                    })
                ) {
                    last++
                    return
                }
            }
        })
    }
    return { gold: gold, silver: silver, bronze: bronze, last: last }
}

function printMedals(medals) {
    return "🥇".repeat(medals.gold) + "🥈".repeat(medals.silver) + "🥉".repeat(medals.bronze) + "💩".repeat(medals.last)
}
