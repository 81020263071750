import React from "react"
import { View, Image } from "react-native"
import useWindowWidth from "../../hooks/useWindowWidth"
import styles from "../../styles"
import logo from "../../assets/wikipedia_240x240.png"
import AccountButton from "./AccountButton"
import MenuToggle from "./MenuToggle"
import NavbarLinks from "./NavbarLinks"
import { useNavigation } from "@react-navigation/native"
import { TouchableOpacity } from "react-native-gesture-handler"
import styled, { css } from "styled-components/native"
import colors from "../../styles/colors"

const StyledNavbar = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    background-color: #ffffff;
    height: 60px;
    z-index: 999;
    padding: 0 16px;
    box-shadow: 4px 4px 16px ${colors.DARKGREY};
    background-image: ${props => `linear-gradient(to right,#b0c4de,#b0c4de24 , #b0c4de)`};
`
const StyledLogo = styled.Image`
    height: 25px;
    width: 25px;
    margin: 10px;
`
function Navbar() {
    const navigation = useNavigation()
    const windowWidth = useWindowWidth()
    return (
        <StyledNavbar>
            <TouchableOpacity
                onPress={() => {
                    navigation.navigate("HomeScreen")
                }}>
                <StyledLogo source={logo} />
            </TouchableOpacity>
            {windowWidth > 1000 ? (
                <>
                    <NavbarLinks compact={false} />
                    <AccountButton />
                </>
            ) : (
                <>
                    <AccountButton />
                    <MenuToggle>
                        <NavbarLinks compact={true} />
                    </MenuToggle>
                </>
            )}
        </StyledNavbar>
    )
}

export default Navbar
