import { View, Text } from "react-native"
import React from "react"
import HomeScreenButton from "./HomeScreenButton"
import { faBars, faChartSimple, faShare } from "@fortawesome/free-solid-svg-icons"

const ShareButton = ({ coords }) => {
    return <HomeScreenButton coords={coords} title="Share" icon={faShare} />
}

export default ShareButton
