import React from "react"
import styled, { css } from "styled-components/native"
import colors from "./../../styles/colors"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"

const Width = 170
const Height = 64

const HomeScreenButtonContainer = styled.TouchableOpacity`
    ${props =>
        props.coords
            ? css`
                  position: absolute;
                  left: ${props => props.coords.x - Width / 2};
                  top: ${props => props.coords.y - Height / 2};
                  width: ${Width};
              `
            : css`
                  margin-bottom: 16px;
              `}
    padding: 12px;
    height: ${Height};
    border-radius: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: ${props => (props.isLeft ? "row-reverse" : "row")};
    box-shadow: 2px 2px 16px ${colors.DARKGREY};
    background-image: ${props => `linear-gradient(to right, ${colors.LIGHTGREY}, ${colors.WHITE})`};
`

const HomeScreenButtonText = styled.Text`
    font-size: 16px;
    align-items: center;
    line-height: 16px;
    font-weight: 600;
    flex-grow: 1;
    text-align: center;
    color: ${colors.BLACK};
    margin: 8px;
`

const HomeScreenButtonIcon = styled(FontAwesomeIcon)`
    flex-shrink: 0;
    flex-grow: 0;
    color: ${colors.BLUE};
    width: 24px;
    height: 24px;
    margin: 8px;
`

const HomeScreenButton = ({ onPress, title, icon, coords }) => {
    const isLeft = coords.x < 0
    return (
        <HomeScreenButtonContainer onPress={onPress} coords={coords} isLeft={isLeft}>
            <HomeScreenButtonText isLeft={isLeft}>{title}</HomeScreenButtonText>
            <HomeScreenButtonIcon icon={icon} isLeft={isLeft} />
        </HomeScreenButtonContainer>
    )
}

export default HomeScreenButton
