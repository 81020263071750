import React from "react"
import { View, Text, TouchableOpacity, TouchableWithoutFeedback } from "react-native"
import useModal from "../../hooks/useSetModal"
import styles from "../../styles"

function Modal({ title, children }) {
    const setModal = useModal()
    return (
        <TouchableWithoutFeedback onPress={() => setModal(null)}>
            <View style={styles.modal_wrapper}>
                <TouchableWithoutFeedback onPress={() => {}}>
                    <View style={styles.modal}>
                        <Text style={styles.modal_title}>{title}</Text>
                        <View style={styles.modal_body}>{children}</View>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        </TouchableWithoutFeedback>
    )
}

export default Modal
