import { View, Text } from "react-native"
import React from "react"
import HomeScreenButton from "./HomeScreenButton"
import { faBars, faChartSimple } from "@fortawesome/free-solid-svg-icons"

const StatisticsButton = ({ coords }) => {
    return <HomeScreenButton coords={coords} title="Statistics" icon={faChartSimple} />
}

export default StatisticsButton
