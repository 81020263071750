import { View, Text, ScrollView } from "react-native"
import styles from "./LobbyStyles"
import { AnimatedDots } from "../global/AnimatedDots"
function PlayersScoreView({ players }) {
    let i = 0
    const pRender = players.map(player => {
        i++
        let medal = i + "."
        let nameSize = 14
        if (i === players.length) {
            medal = "💩"
        }
        if (i === 1 && player.finished) {
            medal = "🥇"
            nameSize = 20
        }
        if (i === 2 && player.finished) {
            nameSize = 18
            medal = "🥈"
        }
        if (i === 3 && player.finished) {
            medal = "🥉"
            nameSize = 16
        }
        let path = ""
        if (player.path) {
            path = player.path
                .map(page => {
                    return page
                })
                .join(" 🠖 ")
        }
        return (
            <View key={player.uid}>
                <Text style={{ fontWeight: "bold", fontSize: nameSize }}>
                    {player.finished && medal} {player.displayname} - Clicks: {player.clicks} {player.finished && medal}
                </Text>
                <Text>
                    {path}
                    {!(player.finished || player.surrendered) && " 🠖 "}
                    {!(player.finished || player.surrendered) && <AnimatedDots />}
                </Text>
                <Text> </Text>
            </View>
        )
    })

    return <ScrollView style={styles.GameFinishScreen_playerscore}>{pRender}</ScrollView>
}

export default PlayersScoreView
