import { View, Text } from "react-native"
import React, { useMemo } from "react"
import styled from "styled-components/native"

const StyledCircularMenuContainer = styled(View)`
    flex: 1;
    align-self: center;
`

export const useCircularMenu = (length, startDeg, radius) => {
    return useMemo(() => {
        let step = (2 * Math.PI) / length
        let angle = startDeg ? startDeg * (Math.PI / 180) : 0
        const res = []
        for (let i = 0; i < length; i++) {
            const x = Math.round(radius * Math.cos(angle))
            const y = Math.round(radius * Math.sin(angle))
            angle += step
            res.push({ x, y })
        }
        return res
    }, [length, startDeg, radius])
}

export default function CircularMenu({ children, radius, maxDeg, startDeg }) {
    let rad = radius
    if (!radius) {
        rad = 150
    }
    let step = (2 * Math.PI) / children.length
    if (step > maxDeg * (Math.PI / 180)) {
        step = maxDeg * (Math.PI / 180)
    }
    let angle = 0
    if (startDeg) {
        angle = startDeg * (Math.PI / 180)
    }
    const elements = children.map(element => {
        const x = Math.round(rad * Math.cos(angle) - element.props.style.width / 2)
        const y = Math.round(rad * Math.sin(angle) - element.props.style.height / 2)
        //let style = [element.props.style, { position: "absolute", left: x, top: y }]
        angle += step
        element.props.style.position = "absolute"
        element.props.style.left = x
        element.props.style.top = y
        return element
        //return React.cloneElement(element, { style })
    })
    return <StyledCircularMenuContainer>{elements.map(element => element)}</StyledCircularMenuContainer>
}
