import React, { useEffect } from "react"

function WikipediaPageRenderer({ url, handleMessage, style }) {
    useEffect(() => {
        // we need a way to detect page changes in the iframe. Using the
        // onload prop of iframe does only work for same-origin. Due to
        // the fact that we want to cluster our Wikipedia servers, we need
        // to find another way to detect page changes. This will be done
        // through the message event listener. The embedded website will
        // post messages if we pass ?iframe=true. The default behavior of <a>
        // in iframe will also be disabled with ?iframe=true. This allows us to
        // manually validate and track stats on page changes.
        const messageHandler = e => handleMessage(e.data)
        window.addEventListener("message", messageHandler)
        return () => window.removeEventListener("message", messageHandler)
    }, [])
    return <iframe src={`${url}?iframe=true`} style={style}></iframe>
}

export default WikipediaPageRenderer
