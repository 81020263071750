import React, { useContext, useState } from "react"
import { Text, TouchableOpacity, View, Switch, TextInput } from "react-native"
import styles from "../../styles"
import Modal from "../global/Modal"
import { useNavigation } from "@react-navigation/native"
import Slider from "@react-native-community/slider"
import colors from "../../styles/colors"
import { LobbyContext } from "../../screens/LobbyScreen"

import { WebSocketContext, WebsocketProvider } from "../../context/WebsocketContext"
function SettingsView() {
    const { ws } = useContext(WebSocketContext)
    const { lobby } = useContext(LobbyContext)
    const [lobbyCopy, setLobbyCopy] = useState(lobby)
    const updateSettingVals = (index, val) => {
        setLobbyCopy(prevLobby => {
            prevLobby.settings[index].setting.value = val
            return {
                ...prevLobby,
            }
        })
    }
    const submitSetting = (settingName, val) => {
        ws.send(JSON.stringify({ msg: "changeSetting", setting: settingName, value: val }))
    }
    let settingIndex = 0
    const settingsviews = lobbyCopy.settings.map(conf => {
        const thisSettingIndex = settingIndex
        settingIndex++
        const setting = conf.setting
        const settingKey = setting.name
        switch (setting.type) {
            case "slider":
                // Disable Slider doesnt work for some Reason
                return (
                    <View style={{ marginVertical: 5 }} key={settingKey}>
                        <Text>
                            {settingKey}: {setting.value}
                            {setting.config.unit}
                        </Text>
                        <Slider
                            onSlidingComplete={val => {
                                submitSetting(settingKey, val)
                            }}
                            key={settingKey}
                            style={styles.create_game_slider}
                            minimumValue={setting.config.min}
                            maximumValue={setting.config.max}
                            step={setting.config.step}
                            value={setting.value}
                            onValueChange={value => updateSettingVals(thisSettingIndex, parseInt(value))}
                            minimumTrackTintColor={colors.GREEN}
                            maximumTrackTintColor={colors.BLACK}
                            disabled={!conf.modifiable}
                        />
                    </View>
                )
            case "wikipage":
                return (
                    <View
                        key={settingKey}
                        style={{ flexDirection: "row", justifyContent: "space-between", marginVertical: 5 }}>
                        <Text>
                            {settingKey}
                            {": "}
                        </Text>
                        <TextInput
                            onBlur={() => {
                                submitSetting(settingKey, setting.value)
                            }}
                            style={{ borderWidth: 1, minWidth: 200 }}
                            editable={conf.modifiable}
                            value={setting.value}
                            onChangeText={value => updateSettingVals(thisSettingIndex, value)}
                        />
                    </View>
                )
            case "toggle":
                return (
                    <View
                        key={settingKey}
                        style={{ flexDirection: "row", justifyContent: "space-between", marginVertical: 5 }}>
                        <Text>
                            {settingKey}
                            {": "}
                        </Text>
                        <Switch
                            disabled={!conf.modifiable}
                            value={setting.value}
                            onValueChange={value => {
                                updateSettingVals(thisSettingIndex, value)
                                submitSetting(settingKey, value)
                            }}
                        />
                    </View>
                )
            default:
                console.log("Don`t know that setting: " + setting.type)
                break
        }
    })
    //<Text style={styles.create_game_form_label}>Time Limit</Text>
    // Go on here :)
    return <View style={styles.create_game_form}>{settingsviews}</View>
}

export default SettingsView
