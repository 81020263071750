import React, { useReducer, useContext } from "react"
import { View } from "react-native"
import styles from "../../styles"
import WikipediaPage from "./WikipediaPage"

import GameNavbar from "./GameNavbar"
import { LobbyContext } from "../../screens/LobbyScreen"
import IngamePlayerList from "./IngamePlayerList"
import GameTimer from "./GameTimer"
import SurrenderButton from "./SurrenderButton"
const hrefRx = new RegExp(`^/wiki/(.*?)/(.*?)/(.*?)$`)

import styled from "styled-components/native"

const StyledPlayerListView = styled.View`
    position: absolute;
    top: 65px;
    left: 20px;
    z-index: 100;
`

function reducer(state, action) {
    switch (action.type) {
        case "loaded":
            return {
                ...state,
                loading: false,
                pages: [...state.pages, { page: state.page, title: action.title || "(No Title)" }],
            }
        case "click":
            const match = action.href?.match(hrefRx)
            if (!match?.length) return state
            return {
                ...state,
                loading: true,
                lang: decodeURIComponent(match[1]),
                checksum: decodeURIComponent(match[2]),
                page: decodeURIComponent(match[3]),
            }
        default:
            return state
    }
}

export function Game() {
    const { lobby } = useContext(LobbyContext)
    let curPath = [lobby.startPage]
    if (lobby.currentUser.path) {
        curPath = lobby.currentUser.path
    }
    const [state, dispatch] = useReducer(reducer, {
        pages: curPath,
        page: curPath[curPath.length - 1],
        loading: true,
        lang: "en",
        checksum: "0", // Reloading probably breaks this? IDK
    })
    let timer = null
    if (lobby.currentUser.timer)
        timer = (
            <View
                style={{
                    position: "absolute",
                    top: 65,
                    right: 10,
                    zIndex: 100,
                    backgroundColor: "rgba(255,255,255,0.9)",
                }}>
                <GameTimer start={lobby.currentUser.timer.time} multiplier={lobby.currentUser.timer.multiplier} />
            </View>
        )

    return (
        <View style={styles.container}>
            <GameNavbar />

            <StyledPlayerListView>
                <IngamePlayerList />
            </StyledPlayerListView>

            {timer}
            <View style={styles.container}>
                <WikipediaPage
                    page={state.page}
                    loading={state.loading}
                    lang={state.lang}
                    checksum={state.checksum}
                    dispatch={dispatch}
                />
            </View>
            <SurrenderButton />
        </View>
    )
}
