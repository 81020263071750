const SERVER_CONFIG = {
    // Need some nice way to switch this for development
    workers: ["https://worker1.wiki-league.com:8080"],
    //workers: ["http://127.0.0.11:8080"],
    // workers: ["http://192.168.1.86:8080"],

    //workers: ["http://localhost:8080"],
    //ws: "ws://127.0.0.11:8080/socket.io/",
    ws: "wss://worker1.wiki-league.com:8080/socket.io/",
    //ws: "ws://192.168.1.86:8080/socket.io/",
    //ws: "ws://localhost:8080/socket.io/",
}

export default SERVER_CONFIG
