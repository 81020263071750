import { useNavigation } from "@react-navigation/native"
import React, { useContext, useState } from "react"
import { TouchableOpacity, Text } from "react-native"
import { AuthContext } from "../../context/AuthContext"
import useModal from "../../hooks/useSetModal"
import styles from "../../styles"
import UsernameModal from "../global/UsernameModal"

function CreateGameLink() {
    const navigation = useNavigation()

    const { token } = useContext(AuthContext)
    const setModal = useModal()
    const createGame = () => {
        if (token) {
            navigation.navigate("LobbyScreen")
        }
        if (!token) {
            setModal(
                <UsernameModal
                    onSuccess={() => {
                        setModal(null)
                        navigation.navigate("LobbyScreen")
                    }}
                />,
            )
        }
    }
    return (
        <>
            <TouchableOpacity style={styles.navbar_link} onPress={createGame}>
                <Text style={styles.navbar_link_text}>Create New Game</Text>
            </TouchableOpacity>
        </>
    )
}

export default CreateGameLink
