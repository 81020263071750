import React, { createContext, useState, useEffect, useContext } from "react"
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth"
import SERVER_CONFIG from "../config/server"

import { AuthContext } from "./AuthContext"

export const WebSocketContext = createContext({
    ws: null,
})

export function WebsocketProvider({ children }) {
    const { auth, token } = useContext(AuthContext)
    const [ws, setWs] = useState()
    const [connected, setConnected] = useState(false)
    const wsConnect = token => {
        console.log("Connecting to WS")
        const tws = new WebSocket(SERVER_CONFIG.ws)
        tws.onopen = event => {
            console.log("Connected to websocket")
            console.log("Authenticating websocket with token")
            event.target.send(JSON.stringify({ token: token, msg: "authenticate" }))
            setConnected(true)
        }
        tws.onerror = err => {
            console.log("Error with Websocket: " + err)
        }
        tws.onclose = () => {
            console.log("Websocket Closed")
            setConnected(false)
        }
        setWs(tws)
    }

    useEffect(() => {
        // This still seems weird. Works right now i guess
        auth.onAuthStateChanged(user => {
            console.log("Auth State Changed")
            if (token != null && ws == null) {
                console.log(`Connecting Websocket`)
                wsConnect(token)
            } else if (user == null) {
                console.log("No user logged in")
                setWs(null)
            }
            return () => {
                // Close Websocket
                console.log("Closing Websocket")
                ws.close()
            }
        })
    }, [token])
    return <WebSocketContext.Provider value={{ ws: ws, connected: connected }}>{children}</WebSocketContext.Provider>
}
