import { View, Text, TouchableOpacity } from "react-native"
import React, { useContext, useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { WebSocketContext } from "../../context/WebsocketContext"
import HomeScreenButton from "./HomeScreenButton"
import { faPerson } from "@fortawesome/free-solid-svg-icons"
import { AuthContext } from "../../context/AuthContext"
import useModal from "../../hooks/useSetModal"
import UsernameModal from "../global/UsernameModal"

export default function QuickSoloPlayButton({ style, coords }) {
    const navigation = useNavigation()
    const { ws, connected } = useContext(WebSocketContext)
    // Disable Button if no Websocket is connected

    let opacity = 1
    if (!connected) {
        opacity = 0.4
    }

    const singlePlayerSettings = [{ "Time per page": 30 }]
    const { token } = useContext(AuthContext)
    const setModal = useModal()
    const onClick = () => {
        if (token) {
            navigation.navigate("LobbyScreen")
            ws.send(JSON.stringify({ msg: "createLobby" }))
            singlePlayerSettings.map(setting => {
                ws.send(JSON.stringify({ msg: "changeSetting", setting: setting.key, value: setting.value }))
            })
        }
        if (!token) {
            setModal(
                <UsernameModal
                    onSuccess={() => {
                        setModal(null)
                        navigation.navigate("LobbyScreen")
                        ws.send(JSON.stringify({ msg: "createLobby" }))
                        singlePlayerSettings.map(setting => {
                            ws.send(
                                JSON.stringify({ msg: "changeSetting", setting: setting.key, value: setting.value }),
                            )
                        })
                    }}
                />,
            )
        }
    }

    // I dont know why style is a array here....
    // console.log(style)
    /*return (
        <TouchableOpacity onPress={() => onClick()} disabled={!connected} style={[style, { opacity: opacity }]}>
            <Text
                style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "white",
                    textShadowColor: "black",
                    textShadowRadius: 5,
                }}>
                Quick Solo Play
            </Text>
        </TouchableOpacity>
    )*/
    return <HomeScreenButton title="Quick Solo Game" icon={faPerson} onPress={onClick} coords={coords} />
}
