import { Button, SafeAreaView, View, Text } from "react-native"
import Navbar from "../HomeScreen/Navbar"
import LobbyScreen from "../../screens/LobbyScreen"
import { useNavigation } from "@react-navigation/native"
import styles from "../../styles"
import PlayersScoreView from "./PlayersScoreView"
import { WebSocketContext } from "../../context/WebsocketContext"
import { useContext, useState } from "react"
import { LobbyContext } from "../../screens/LobbyScreen"
import react from "react"
import lobbyStyles from "./LobbyStyles"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { TouchableOpacity } from "react-native"
export function GameFinishView({ setEndscreen }) {
    const { lobby } = useContext(LobbyContext)
    const { ws } = useContext(WebSocketContext)

    const back2Lobby = () => {
        setEndscreen(false)
    }
    const playAgain = () => {
        ws.send(JSON.stringify({ msg: "startGame" }))
    }
    console.log("Game Finished")
    console.log(lobby)

    const [page, setPage] = useState(0)
    const pageUp = () => {
        if (!lobby.history) {
            // No history.. nothing to do
            setPage(0)
            return
        }
        if (
            (page === lobby.history.length - 1 && lobby.state === "wait") ||
            (lobby.state === "game" && page == lobby.history.length)
        ) {
            // Already at the end
            return
        }
        setPage(page + 1)
    }

    const pageDown = () => {
        if (!lobby.history) {
            // No history.. nothing to do
            setPage(0)
            return
        }
        if (page === 0) {
            // Already at the end
            return
        }
        setPage(page - 1)
    }
    let players = <Text> Players loading </Text>
    if (lobby.state === "wait") {
        if (lobby.history) {
            players = <PlayersScoreView players={lobby.history[lobby.history.length - 1 - page].users} />
        }
    } else if (lobby.state === "game") {
        if (page === 0) {
            players = <PlayersScoreView players={lobby.users} />
        } else {
            if (lobby.history) {
                players = <PlayersScoreView players={lobby.history[lobby.history.length - page].users} />
            }
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <Navbar />
            <View style={lobbyStyles.GameFinishScreen_wrapper}>
                <View style={lobbyStyles.GameFinishScreen_buttons_LRwrapper}>
                    <TouchableOpacity style={styles.menu_button} onPress={pageDown}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </TouchableOpacity>
                    <Text>{page}</Text>
                    <TouchableOpacity style={styles.menu_button} onPress={pageUp}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </TouchableOpacity>
                </View>
                <Text style={lobbyStyles.GameFinishScreen_header}>Game Stats:</Text>
                {players}
                <View style={lobbyStyles.GameFinishScreen_buttons_wrapper}>
                    <Button title="Back to Lobby" onPress={back2Lobby} />
                    <Button title="Play Again" onPress={playAgain} />
                </View>
            </View>
        </SafeAreaView>
    )
}
