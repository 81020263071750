import React, { useCallback, useReducer } from "react"
import SERVER_CONFIG from "../../config/server"
import WikipediaPageRenderer from "./WikipediaPageRenderer"
import { ActivityIndicator } from "react-native"
import { useContext } from "react"
import { AuthContext } from "../../context/AuthContext"

import styled from "styled-components/native"
import colors from "../../styles/colors"

const StyledActivityIndicator = styled.ActivityIndicator`
    position: "absolute";
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: "rgba(0,0,0,0.2)";
`
const StyledWikipediaPageRenderer = styled(WikipediaPageRenderer)`
    flex: 1;
`
function WikipediaPage({ page, loading, lang, checksum, dispatch }) {
    const { auth, token } = useContext(AuthContext)
    const url = `${
        SERVER_CONFIG.workers[Math.floor(Math.random() * SERVER_CONFIG.workers.length)]
    }/wiki/${encodeURIComponent(lang)}/${encodeURIComponent(checksum)}/${encodeURIComponent(page)}/${token}`
    // Idk... using styles.container here breaks stuff
    return (
        <>
            <WikipediaPageRenderer url={url} handleMessage={dispatch} style={{ flex: 1 }} />
            {loading && <StyledActivityIndicator size="large" color="#000000" />}
        </>
    )
}

export default WikipediaPage
