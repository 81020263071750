import React from "react"
import { View } from "react-native"
import styles from "../../styles"
import HomeLink from "./HomeLink"
import CreateGameLink from "./CreateGameLink"

function NavbarLinks({ compact }) {
    return (
        <View style={compact ? styles.navbar_links_compact : styles.navbar_links}>
            <HomeLink />
            <CreateGameLink />
        </View>
    )
}

export default NavbarLinks
