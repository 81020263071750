import React, { createContext, useEffect, useState } from "react"
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyDwKYjw0P8Aeq1GrkTh5dMuJsqkzbxrrg4",
    authDomain: "wikileague-3b89e.firebaseapp.com",
    databaseURL: "https://wikileague-3b89e.firebaseio.com",
    projectId: "wikileague-3b89e",
    storageBucket: "wikileague-3b89e.appspot.com",
    messagingSenderId: "sender-id", //TODO ???
    appId: "1:835831111092:android:50cff0ad046370147266e1",
    measurementId: "G-measurement-id", //TODO ???
}

initializeApp(firebaseConfig)

export const AuthContext = createContext({
    auth: getAuth(),
    token: null,
})

export function AuthProvider({ children }) {
    const auth = getAuth()
    const [token, setToken] = useState(null)
    useEffect(() => {
        auth.onIdTokenChanged(user => {
            // Some check could be good here
            console.log("ID Token Changed")
            console.log(user)
            if (user == null) {
                setToken(null)
                return
            }
            user.getIdToken().then(newToken => {
                if (token == null || token != newToken) {
                    setToken(newToken)
                } else if (token == null) {
                    // Maybe unreferenced
                    setToken(null)
                }
            })
        })
    })

    return <AuthContext.Provider value={{ auth: auth, token: token }}>{children}</AuthContext.Provider>
}
