import { View, Text } from "react-native"
import React from "react"
import WikiLeagueLogin from "./WikiLeagueLogin"
import styled from "styled-components/native"
import GoogleLogin from "./GoogleLogin"

const LoginMethods = styled.View`
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    flex: 1;
    flex-grow: 1;
    min-height: 200px;
    width: 100%;
`

// This stuff is badly styled. Looks ok but Meh x)
const Login = ({ onSuccess }) => {
    const thisOnSuccess = () => {
        if (onSuccess) {
            onSuccess()
        }
    }

    return (
        <LoginMethods>
            <WikiLeagueLogin onSuccess={thisOnSuccess} />
            <GoogleLogin onSuccess={thisOnSuccess} />
        </LoginMethods>
    )
}

export default Login
