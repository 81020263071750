import { Text, TextInput, TouchableOpacity, View } from "react-native"
import styles from "../../styles"
import Modal from "./Modal"
import styled from "styled-components/native"
import colors from "../../styles/colors"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faJoint } from "@fortawesome/free-solid-svg-icons"
import { AuthContext } from "../../context/AuthContext"
import { useContext, useState } from "react"
import { signInAnonymously } from "firebase/auth"
import axios from "axios"
import SERVER_CONFIG from "../../config/server"
import WikiLeagueLogin from "./WikiLeagueLogin"
import Login from "./Login"

const url = `${SERVER_CONFIG.workers[Math.floor(Math.random() * SERVER_CONFIG.workers.length)]}/user/modify`

const ModalContainer = styled.View`
    background-color: ${colors.White};
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
`
const ModalTextInput = styled.TextInput`
    border-radius: 10px;
    border-width: 1px;
    border-color: ${colors.DARKGREY};
    padding: 10px;
    margin: 10px;
    justify-content: center;
`
const SubmitButton = styled.TouchableOpacity`
    background-color: ${colors.WHITE};
    border-radius: 10px;
    border-width: 1px;
    border-color: ${colors.DARKGREY};
    padding: 10px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-image: ${props => `linear-gradient(to right, #d9e7ff, ${colors.WHITE})`};
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
`
function UsernameModal({ onSuccess }) {
    const { auth, token } = useContext(AuthContext)
    const [userName, setUserName] = useState("")
    const thisOnSuccess = () => {
        if (onSuccess) {
            onSuccess()
        }
    }
    const onSubmit = () => {
        signInAnonymously(auth).then(UC => {
            axios
                .post(url, { name: userName }, { headers: { Authorization: "Bearer " + UC.user.accessToken } })
                .then(response => {
                    thisOnSuccess()
                })
                .catch(function (error) {
                    // handle error
                    console.log(error)
                })
        })
        // navigation.navigate("WaitScreen")
    }
    return (
        <Modal title="Need Username">
            <ModalContainer>
                <Text style={styles.modalText}>Please enter an username to continue.</Text>
                <ModalTextInput onChangeText={value => setUserName(value)} placeholder="Username" />
                <SubmitButton onPress={onSubmit}>
                    <StyledFontAwesomeIcon icon={faJoint} />
                    <Text style={styles.modalButtonText}>Submit</Text>
                </SubmitButton>

                <Text>Or Login with existing Account:</Text>
                <Login onSuccess={thisOnSuccess} />
            </ModalContainer>
        </Modal>
    )
}

export default UsernameModal
