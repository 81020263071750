import React, { useContext } from "react"
import { SafeAreaView, View, Text, Image } from "react-native"
import Navbar from "../components/HomeScreen/Navbar"
import { ModalProvider } from "../context/ModalContext"
import styles from "../styles"
import logoIcon from "../assets/logoIcon.png"
import wikilogo from "../assets/wikipedia_240x240.png"
import { WebSocketContext } from "../context/WebsocketContext"
import CircularMenu, { useCircularMenu } from "../components/HomeScreen/CircularMenu"
import CreatePrivateLobbyButton from "../components/HomeScreen/CreatePrivateLobbyButton"
import QuickSoloPlayButton from "../components/HomeScreen/QuickSoloPlayButton"
import JoinFriendsButton from "../components/HomeScreen/JoinFriendsButton"
import StatisticsButton from "../components/HomeScreen/StatisticsButton"
import SettingsButton from "../components/HomeScreen/SettingsButton"
import ShareButton from "../components/HomeScreen/ShareButton"
import useWindowWidth from "../hooks/useWindowWidth"
import styled from "styled-components/native"

const numMenuButtons = 8
const startDeg = 315
const radius = 160

const BackgroundImage = styled.Image`
    position: absolute;
    width: 400px;
    height: 400px;
    opacity: 0.2;
`

function HomeScreen() {
    const { ws } = useContext(WebSocketContext)
    const windowWidth = useWindowWidth()
    const circular = windowWidth > 500
    const circularMenu = useCircularMenu(numMenuButtons, startDeg, radius)
    return (
        <SafeAreaView style={styles.container}>
            <Navbar />
            <View style={styles.home_wrapper}>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <BackgroundImage source={wikilogo} />
                    <View>
                        <CreatePrivateLobbyButton coords={circular && circularMenu[0]} />
                        <JoinFriendsButton coords={circular && circularMenu[1]} />
                        <QuickSoloPlayButton coords={circular && circularMenu[2]} />
                        <SettingsButton coords={circular && circularMenu[4]} />
                        <ShareButton coords={circular && circularMenu[5]} />
                        <StatisticsButton coords={circular && circularMenu[6]} />
                    </View>
                </View>
            </View>
        </SafeAreaView>
    )
}

export default HomeScreen
