import { View } from "react-native"
import React, { useContext } from "react"
import { TouchableOpacity } from "react-native-gesture-handler"
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome"
import { faPersonRunning } from "@fortawesome/free-solid-svg-icons"
import { WebSocketContext } from "../../context/WebsocketContext"
import styled from "styled-components/native"
import colors from "../../styles/colors"
import styles from "../../styles"

const StyledLeaveLobbyButton = styled.View`
    flex-direction: row;
    background-color: ${colors.BLUE};
    border-radius: 5px;
    padding: 10px;
    align-self: auto;
`
const Text = styled.Text`
    color: ${colors.WHITE};
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${colors.WHITE};
`
const StyledButton = styled.View`
    flex-direction: row;
`
const LeaveLobbyButton = () => {
    const { ws } = useContext(WebSocketContext)
    const leaveLobby = () => {
        if (ws) {
            ws.send(JSON.stringify({ msg: "leaveLobby" }))
        }
    }
    return (
        <TouchableOpacity onPressOut={leaveLobby}>
            <StyledLeaveLobbyButton style={styles.wait_start_button}>
                <StyledFontAwesomeIcon icon={faPersonRunning} inverse />
                <Text>Leave Lobby</Text>
            </StyledLeaveLobbyButton>
        </TouchableOpacity>
    )
}

export default LeaveLobbyButton
