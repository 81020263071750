import React, { createContext, useState } from "react"

const ModalContext = createContext()

export function ModalProvider({ children }) {
    const [modal, setModal] = useState(null)
    return (
        <ModalContext.Provider value={setModal}>
            {children}
            {modal}
        </ModalContext.Provider>
    )
}

export default ModalContext
