import { useContext } from "react"
import { View, Text } from "react-native"
import styled, { css } from "styled-components/native"
import { LobbyContext } from "../../screens/LobbyScreen"
import colors from "../../styles/colors"
import { BoldText } from "../styledcomps/StyledComps"

const GameInfoWrap = styled.View`
    flex: 2;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: "rgba(0,0,0,0.0)";
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    //box-shadow: 2px 2px 8px ${colors.DARKGREY};
`
const GameInfoEntry = styled.View`
    align-items: center;
`
function GameInfo() {
    const { lobby } = useContext(LobbyContext)
    console.log("GameInfo")
    console.log(lobby)
    let clicks = 0
    if (lobby.currentUser.clicks) {
        clicks = lobby.currentUser.clicks
    }
    let currentPage = lobby.startPage
    if (lobby.currentUser.path) {
        currentPage = lobby.currentUser.path[lobby.currentUser.path.length - 1]
    }
    return (
        <GameInfoWrap>
            <GameInfoEntry>
                <Text>Players Finished:</Text>
                <Text>{lobby.users.filter(user => user.finished).length}</Text>
            </GameInfoEntry>
            <GameInfoEntry>
                <Text>Start: {lobby.startPage}</Text>
                <BoldText>End: {lobby.targetPage}</BoldText>
                <Text>Clicks: {clicks}</Text>
            </GameInfoEntry>
            <GameInfoEntry>
                <Text>Current Page:</Text>
                <Text>{currentPage}</Text>
            </GameInfoEntry>
        </GameInfoWrap>
    )
}

export default GameInfo
