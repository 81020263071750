import { View, Text } from "react-native"
import React, { useContext } from "react"
import styled from "styled-components/native"
import { TouchableOpacity } from "react-native-gesture-handler"
import { AuthContext } from "../../context/AuthContext"
import { signInWithPopup, getAuth, linkWithCredential, linkWithPopup } from "firebase/auth"
import { GoogleAuthProvider } from "firebase/auth"
import colors from "../../styles/colors"

// Style this with actual Google button

const GoogleButton = styled(TouchableOpacity)`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${colors.BLUE};
    height: 36px;
    width: 36px;
    margin: 12px;
`
const WhiteText = styled.Text`
    color: ${colors.WHITE};
    font-size: 30px;
`

const GoogleLogin = ({ onSuccess }) => {
    const { auth } = useContext(AuthContext)
    const provider = new GoogleAuthProvider()
    const googlePopUpSignIn = () => {
        console.log("Logging in per Popup")
        signInWithPopup(auth, provider)
            .then(result => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result)
                const token = credential.accessToken
                // The signed-in user info.
                const user = result.user
                console.log("Logged In")
                if (onSuccess) {
                    onSuccess()
                }
                // ...
            })
            .catch(error => {
                // Handle Errors here.
                const errorCode = error.code
                const errorMessage = error.message
                // The email of the user's account used.
                const email = error.customData.email
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error)

                console.log(error)
                // ...
            })
    }
    return (
        <GoogleButton onPress={googlePopUpSignIn}>
            <WhiteText>G</WhiteText>
        </GoogleButton>
    )
}

export default GoogleLogin
