const colors = {
    WHITE: "#F9F9F9",
    BLACK: "#272727",
    YELLOW: "#FFE45E",
    RED: "#E84855",
    BLUE: "#2978A0",
    GREEN: "#358600",
    LIGHTGREY: "#e7e7e7",
    DARKGREY: "#c2c2c2",
}

export default colors
