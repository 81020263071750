import { useNavigation } from "@react-navigation/native"
import React from "react"
import { TouchableOpacity, Text } from "react-native"
import styles from "../../styles"

function HomeLink() {
    const navigation = useNavigation()
    return (
        <TouchableOpacity style={styles.navbar_link} onPress={() => navigation.navigate("HomeScreen")}>
            <Text style={styles.navbar_link_text}>Home</Text>
        </TouchableOpacity>
    )
}

export default HomeLink
